import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Link from "../components/link"
import { ButtonLink } from "../components/button"
import { Row, Shim, PageTitle } from "../components/row"
import PhotoGrid from "../components/photo-grid"
import SEO from "../components/seo"

const pageTitle = "Cakes"
const ImagesQuery = graphql`
  fragment customFluid on File {
    id
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
        originalName
      }
    }
  }

  query {
    weddingCakes: allFile(
      filter: { relativePath: { regex: "/wedding-cakes/" } }
    ) {
      edges {
        node {
          ...customFluid
        }
      }
    }

    celebrationCakes: allFile(
      filter: { relativePath: { regex: "/celebration-cakes/" } }
    ) {
      edges {
        node {
          ...customFluid
        }
      }
    }

    signatureCakes: allFile(
      filter: { relativePath: { regex: "/signature-cakes/" } }
    ) {
      edges {
        node {
          ...customFluid
        }
      }
    }
  }
`

export default () => (
  <Layout>
    <SEO title={pageTitle} />
    <Row>
      <PageTitle>{pageTitle}</PageTitle>
      <StaticQuery
        query={ImagesQuery}
        render={data => (
          <>
            <PhotoGrid name="weddingCakes" images={data.weddingCakes.edges} />
            <Shim>
              <h2>Wedding Cakes</h2>
              <p>
                Our wedding cakes are award-winning, hand crafted works of art.
                From the inside out, they are custom designed to fit each
                client's unique style and tastes. Please&nbsp;
                <a href="https://store.frostedcakery.com/s/appointments">
                  schedule your consultation online
                </a>
                &nbsp;with our cake designer.
              </p>

              <ButtonLink
                to="/cakes/gallery/wedding-cakes"
                data-title="View full gallery"
              >
                View full gallery
              </ButtonLink>

              <h3>Consultations</h3>
              <p>Consults start at $25 which includes; a 45 minute sit down, on Tuesday or Wendesday, 
                with our multi-award winning, 30+ years experience, cake designer at our quaint Bullard & West location. 
                The client will have an opportunity to collaborate with the designer about their cake and/or dessert display. 
                The designer will provide a sketch and quote of your ideas. Samples of our award-winning cake are 
                available upon request at an additional fee.</p>

              <p>We recommend scheduling your wedding cake consultation at least six months to nine months in advance. This ensures the end result is exactly what you dreamed.</p>
              <p>
                <ButtonLink
                  darkgold
                  to="https://store.frostedcakery.com/s/appointments"
                  data-title="Book a consultation"
                >
                  Book a consultation
                </ButtonLink>
              </p>

              <h3>Pricing</h3>
              <p>
                Wedding cake pricing <strong>starts at $7/serving</strong> plus cost of design, delivery, 
                set-up and rental.</p>
              <p>
                <ButtonLink to="/terms" data-title="View terms and conditions">
                  View terms and conditions
                </ButtonLink>
              </p>
            </Shim>

            <PhotoGrid
              name="celebrationCakes"
              images={data.celebrationCakes.edges}
            />

            <Shim>
              <h2>Celebration Cakes</h2>
              <p>
                Our celebration cakes are custom-created for events such as baby 
                showers, birthdays and baptisms. We recommend a consultation with 
                our cake designer for events with 50 or more guests. Smaller 
                events may also require a consultation to guarantee the highest 
                quality cake. We suggest 3 month advance notice for custom 
                celebration cakes during our peak seasons (March-June and 
                September-October) and typically 30 days during off seasons. 
                Please <Link to="/contact">message us online</Link> to inquire 
                about your special event cake now.
              </p>

              <p>
                <ButtonLink
                  to="/cakes/gallery/celebration-cakes"
                  data-title="View full gallery"
                >
                  View full gallery
                </ButtonLink>

              </p>
            </Shim>

            <PhotoGrid
              name="celebrationCakes"
              images={data.signatureCakes.edges}
            />

            <Shim>
              <h2>Signature Cakes</h2>
              <p>
                A variety of our Signature Cakes are available for short 
                notice preorder online (at least 5 or more business days 
                is required) or for purchase at our Tower District store. 
                Some of our classic flavors include: Salted Caramel, 
                Rainbow Jello, Birthday Confetti and many more! We are 
                NOW offering a <a href="https://store.frostedcakery.com/shop/canvas-cakes/7">NEW line of DIY Canvas Cakes</a>&nbsp;
                exclusively for preorder online as well! All of our 
                Signature and Canvas Cakes are perfect for last minute 
                events such as small weddings, office parties, birthday 
                parties or showers. Shop NOW by clicking the button below.
              </p>

              <p>
                <ButtonLink darkgold to="contact" data-title="Contact us">
                  Contact us
                </ButtonLink>

                <ButtonLink
                  to="https://store.frostedcakery.com/shop/cakes/4"
                  data-title="Shop online"
                >
                  Shop online
                </ButtonLink>
              </p>
            </Shim>
          </>
        )}
      />
    </Row>
  </Layout>
)
